@tailwind base;
@tailwind components;
@tailwind utilities;
 body{
    overflow-x:hidden
 }
 .theme-btn {
 
  
   display: inline-flex;
   font-size: 16px;
   font-weight: 700;
   padding: 24px 50px;
   border-radius: 0;
   text-transform: uppercase;
   transition: all .3s ease-in-out;
   position: relative;
   z-index: 1;
   line-height: 1;
}
.theme-btn:hover:after, .theme-btn:hover:before {
   width: 100%;
   color:'white'
}

.theme-btn:after {
   bottom: 0;
   right: 0;
}
.theme-btn:after, .theme-btn:before {
   content: "";
   background-color: black;
   color:white;
   width: 0;
   height: 50%;
   position: absolute;
   transition: all .3s cubic-bezier(.785,.135,.15,.86);
   z-index: -1;
}
.theme-btn:before {
   top: 0;
   left: 0;
}
 .hero-content {
   background-color: #012F53;
  
   -webkit-clip-path: polygon(0 15%, 0 11%, 11% 0, 85% 0, 100% 0, 100% 15%, 100% 88%, 91% 100%, 94% 100%, 15% 100%, 0 100%, 0 85%);
   clip-path: polygon(0 15%, 0 11%, 11% 0, 85% 0, 100% 0, 100% 15%, 100% 88%, 91% 100%, 94% 100%, 15% 100%, 0 100%, 0 85%);
   /* max-width: 870px; */
   position: relative;
}
/* *{
   outline:1px solid red;
} */
.bg1{
   background-image: url('./Assets/cta-bg\ \(1\).jpg');
   background-size: cover;
   background-repeat: no-repeat;
}
.bg2{
   background-image: url('./Assets/bg-shape\ \(1\).png');
   background-size: cover;
   background-repeat: no-repeat;
}
.bg3{
   background-image: url('./Assets/bg-shape\ \(2\).png');
   background-size: cover;
   background-repeat: no-repeat;
}
.bg5{
   background-image: url('./Assets/contact-bg.jpg');
   background-size: cover;
   background-repeat: no-repeat;
}
.bg6{
   background-image: url('./Assets/bg.jpg');
   background-size: cover;
   background-repeat: no-repeat;
}
.bg7{
   background-image: url('./Assets/breadcrumb-bg.jpg');
   background-size: cover;
   background-repeat: no-repeat;
}
.ab{
   z-index: 1;
}